/* NOTE: the order of these styles DO matter */

.SingleDatePicker_picker {
  z-index: 5;
}

.DateInput__block {
  display: flex;
}

.DateInput_input {
  flex-grow: 1;
  width: initial;
  font-size: 16px;
  line-height: inherit;
  font-family: Raleway, sans-serif;
  color: #555;
  font-weight: inherit;
}

.DateInput_input__focused {
  border-bottom: none;
  padding-bottom: 11px;
}

.DayPicker__withBorder {
  border-radius: 6px;
  box-shadow: 0 3px 4px #ccc;
}

/* Will edit selected date or the endpoints of a range of dates */
.CalendarDay__selected {
  background: #e6ecfe;
  color: #555;
  border: #555;
}

/* Will edit when hovered over. _span style also has this property */
.CalendarDay__selected:hover {
  background: #999;
  color: white;
  border: #555;
}

/* 
 * Will edit when the second date (end date) in a range of dates
 * is not yet selected. Edits the dates between your mouse and said date
 */
/* .CalendarDay__hovered_span:hover, */
/* .CalendarDay__hovered_span { */
/* background: brown; */
/* } */
