body {
  margin: 0;
  padding: 0;
  font-family: Raleway, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f9f9f9;
  color: #555;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: #6a98c5;
}

a:visited {
  color: #636d77;
}

input[type='text'],
input[type='password'],
textarea {
  color: #555 !important;
  font-family: Raleway, sans-serif !important;
  display: inline-block;
  padding: 10px 12px;
  max-width: 100%;
  border-radius: 2px;
  border: 1px solid #777;
  font-size: 0.9em;
}

textarea {
  resize: vertical;
  height: 80px;
}

table.eventual-table {
  padding: 12px;
  border-collapse: collapse;
  background-color: white;
  border-radius: 6px;
  box-shadow: 0 3px 4px #ccc;
}

.eventual-table > tbody tr:hover {
  background-color: #ddd;
}

.eventual-table th,
.eventual-table td {
  padding: 8px 12px;
  border-right: 1px solid;
  border-bottom: 1px solid;
  text-align: center;
}

.eventual-table td:first-child {
  text-align: left;
}

.eventual-table th:last-child,
.eventual-table td:last-child {
  border-right: none;
}

.eventual-table tr:last-child td {
  border-bottom: none;
}

.material-icons {
  display: flex;
}

table .material-icons {
  display: block;
}

.react-sweet-progress-symbol {
  display: none !important;
}

.react-sweet-progress-line,
.react-sweet-progress-line-inner {
  border-radius: 0 !important;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
  transform: translateY(30px);
}

.react-tabs__tab-panel--selected {
  animation-name: fadeIn;
  animation-duration: 500ms;
  animation-timing-function: linear;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.react-tooltip-overrides {
  z-index: 9999 !important;
}
